* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat";
  font-size: 12px;
}

body {
  margin: 0% 20%;
}

code {
  font-family: source-code-pro;
}

h4 {
  font-size: 1rem;
  color: rgb(56, 56, 56);
}

h3 {
  font-size: 1.5rem;
  color: rgb(56, 56, 56);
  line-height: 2.5rem;
  margin: 2rem 0rem;
}

a {
  color: rgb(56, 56, 56);
}
